<template>
	<div class="card">
		<div class="grid formgrid">
			<div class="col-12 xl:col-12">
				<h5>{{$route.name}}</h5>
			</div>
			<div class="col-12">
				<DataTable :value="products.filter(val => val.tipo == opcion.code)" :paginator="true" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
                            <Dropdown v-model="opcion" :options="opciones" optionLabel="nombre" placeholder="Selecciona..." class="mr-2"></Dropdown>
                            <div v-if="insert">
                                <FileUpload chooseLabel="Nuevo" uploadLabel="Cargar" mode="basic" :name="token" :url="url+'upload.php'"  accept=".jpg" :maxFileSize="10000000" @upload="myUploader(token+'.jpg')" :auto="true" v-if="opcion.code == 1 || opcion.code == 3"/>
                                <Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="Nuevo"  v-if="opcion.code == 2"/>
                            </div>
                        </div>
					</template>
					<Column field="img" header="IMAGEN/VIDEO" headerStyle="width: 20%">
						<template #body="slotProps">
                            <img :src="url+'upload/'+slotProps.data.url" style="width: 100%;" v-if="opcion.code == 1 || opcion.code == 3"/>
							<iframe :src="slotProps.data.url" frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen v-if="opcion.code == 2"/>
						</template>
					</Column>
					<Column field="url" header="URL">
						<template #body="slotProps">
							<div  v-if="opcion.code == 1 || opcion.code == 3">
								{{url+'upload/'+slotProps.data.url}}
							</div>
							<div  v-if="opcion.code == 2">
								{{slotProps.data.url}}
							</div>
						</template>
					</Column>
					<Column :header="Acciones" headerStyle="width: 10%">
						<template #body="slotProps">
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="Confirmar(slotProps.data)" v-if="del"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'Editar ID'+product.id" :modal="true" class="fluid">
					<div class="fluid">       
						<div class="grid fluid mt-3">
							<div class="col-12">
								<label for="name">URL</label>
								<InputText id="name" v-model="product.url" required="true" style="width: 100%;"/>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="productDialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteDialog" style="width: 30vw" :header="'Eliminar ID'+product.id" :modal="true">
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Si" icon="pi pi-check" class="p-button-secondary" @click="Eliminar" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteDialog = false"/>
					</template>
				</Dialog>
			
			</div>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','LandingPage');
    import Token from "uuid-token-generator";

	export default {
		data() {
			return {
				nuevo: false,
				insert: false,
				edit: false,
				del: false,
				product: {},
				products: [],
				permisos: {},
				niveles: null,
				productDialog: false,
				permisosDialog: false,
				deleteDialog: false,
				selectedCustomers: null,
				filters: {},
				size: '60vw',
                opcion: {nombre: 'Sleccionar...', code: 0},
				opciones: [
                    {nombre: 'Sleccionar...', code: 0},
                    {nombre: 'Slider', code: 1},
					{nombre: 'Canal Yotube', code: 2},
					{nombre: 'Especialidades', code: 3},
                ],
                token: null,
                url: process.env.VUE_APP_API,
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
            const tokgen = new Token();
            this.token = tokgen.generate();
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {	
				this.permisos = null;
				this.products = null;
				Consulta.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.products = response.result;
					this.$store.commit('Loading');
				}); 
			},
			Nuevo() {
				this.product = {};
                this.product.url = '';
                this.product.tipo = this.opcion.code;
				this.productDialog = true;
			},
			Confirmar(product) {
				this.product = product;
				this.deleteDialog = true;
			},
			Guardar() {
					Consulta.Procesar('INS',{
					product: this.product,
				}).then(response => {
					//this.$store.state.error =response;
					this.$store.commit('Loading');
					this.$toast.add(Consulta.Mens(response.result));
					if (response.result) {
						this.Mostrar();
						this.productDialog = false;
						this.product = {};
					}
				});
			},
			Eliminar() {
				Consulta.Procesar('DEL',{
					product: 	this.product
				}).then(response => {
					this.$store.commit('Loading');
                    //this.$store.state.error =response;
					this.$toast.add(Consulta.Mens(response.result));
					if (response.result) {this.Mostrar();}
				});
				this.deleteDialog = false;
				this.product = {};
			},
            myUploader(img) {
                let product = {
                    img: img,
                    tipo: this.opcion.code
                }
                Consulta.Procesar('INS',{
                    product: product,
                }).then(response => {
                    this.$store.commit('Loading');
                    //this.$store.state.error =response;
                    this.$toast.add(Consulta.Mens(response.result));
					if (response.result) {this.Mostrar();}
                    const tokgen = new Token(256);
                    this.token = tokgen.generate();
                });
            },

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
